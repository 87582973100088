<template>
  <b-container fluid>
    <b-row align-v="center">
      <b-col md="auto">
        <h2>Miscellaneous Calls</h2>
      </b-col>
      <b-col>
        <b-button variant="primary" class="my-2" @click="addCallLog($event.target)">Add log</b-button>
      </b-col>
    </b-row>
    
    <b-form-group
      label="Filter"
      label-cols-sm="2"
      label-align-sm="right"
      label-size="sm"
      label-for="filterInput"
      class="mb-2"
    >
      <b-input-group size="sm">
        <b-form-input
          v-model="filter"
          type="search"
          id="filterInput"
          placeholder="Type to Search"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          <b-form-checkbox class="mx-4" v-model="showHidden">Show deleted</b-form-checkbox>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="calllog-table"
      align="center"
    ></b-pagination>
    <b-table striped hover
      id="calllog-table"
      :items="reportList"
      :fields="reportFields"
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      primary-key="id"
      :sort-by="this.$store.state.sortStates['miscellaneous'].column"
      :sort-desc="this.$store.state.sortStates['miscellaneous'].descending"
      @sort-changed="sortingChanged"
      sort-icon-left>
      <template v-slot:cell(updatedAt)="data">
        {{ new Date(data.value).toLocaleDateString('en-GB', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }) }}
      </template>
      <template v-slot:cell(actions)="data">
        <b-button size="sm" @click="editCallLog(data.item, $event.target)">Edit</b-button>
      </template>
      <template v-slot:cell(callTime)="data">
        {{ new Date(data.value).toLocaleDateString('en-GB', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }) }}
      </template>
    </b-table>
    <b-modal id="callLogModal" title="Edit report" size="lg" @ok="commitCallLog" @hide="hideCallLog">
      <template v-slot:modal-footer="{ ok, cancel, hide }">
        <b-button variant="primary" @click="ok()">OK</b-button>
        <b-button variant="secondary" @click="cancel()">Cancel</b-button>
        <b-button variant="outline-danger" @click="hide('delete')">Delete</b-button>
      </template>
      <template v-slot:default="">
      <b-container fluid>
        <b-row>
          <b-col>
            <b-form-group label="Comment" label-for="input-comment" label-cols-md="3">
              <b-form-textarea id="input-comment" v-model="callLogModal.comment" rows="3" max-rows="10"></b-form-textarea>
            </b-form-group>
            <b-form-group label-cols-md="3">
              <b-form-checkbox v-model="callLogModal.isCustodyCall">Was this a custody call?</b-form-checkbox>
            </b-form-group>
            <b-form-group label="Name/Alias of caller" label-for="input-caller-name" label-cols-md="3">
              <b-form-input id="input-caller-name" v-model="callLogModal.callerName"></b-form-input>
            </b-form-group>
            <b-form-group label-cols-md="3">
              <b-form-checkbox v-model="callLogModal.isAlias">Is this an alias?</b-form-checkbox>
            </b-form-group>
            <b-form-group label="Solicitor/law firm used" label-for="input-law-firm" label-cols-md="3">
              <b-form-input id="input-law-firm" v-model="callLogModal.lawFirmUsed"></b-form-input>
            </b-form-group>
            <b-form-group label="Call taken by" label-for="input-taken-by" label-cols-md="3">
              <b-form-input id="input-taken-by" v-model="callLogModal.takenBy"></b-form-input>
            </b-form-group>
            <b-form-group label="Call taken at" label-for="input-taken-at" label-cols-md="3">
              <datetime type="datetime" id="input-taken-at" v-model="callLogModal.callTime"></datetime>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
export default {
  data () {
    return {
      perPage: 50,
      currentPage: 1,
      filter: null,
      showHidden: false,
      callLogModal: {
        id: null,
        callTime: null,
        comment: null,
        lawFirmUsed: null,
        callerName: null,
        isAlias: null,
        isCustodyCall: null,
        isHidden: null,
      }
    }
  },
  computed: {
    reportFields () {
      return [
        { key: 'callTime', label: 'Time of call', sortable: true },
        { key: 'comment', label: 'Comment', sortable: true },
        { key: 'callerName', label: 'Name', sortable: true },
        { key: 'isCustodyCall', label: 'Custody call?', sortable: true },
        { key: 'actions', label: '' }
      ]
    },
    reportList () {
      return this.$store.state.callLogs.filter((x) => x.isHidden == this.showHidden)
    },
    rows () {
      return this.reportList.length
    }
  },
  methods: {
    addCallLog (target) {
      this.callLogModal.id = null
      this.callLogModal.callTime = new Date(Date.now()).toISOString()
      this.callLogModal.comment = ""
      this.callLogModal.lawFirmUsed = "",
      this.callLogModal.callerName = "",
      this.callLogModal.isAlias = false,
      this.callLogModal.isCustodyCall = false,
      this.callLogModal.isHidden = false,
      this.callLogModal.takenBy = this.$store.state.nickname,
      this.$root.$emit('bv::show::modal', 'callLogModal', target)
    },
    editCallLog (item, target) {
      this.callLogModal = Object.assign({}, item)
      this.$root.$emit('bv::show::modal', 'callLogModal', target)
    },
    commitCallLog () {
      if (this.callLogModal.id !== null) {
        this.$io.socket.put('/api/v1/calllog/' + this.callLogModal.id, this.callLogModal, (resData, jwRes) => {
          if (jwRes.statusCode == 200) {
            this.$store.commit('setCallLog', resData)
            this.$bvToast.toast('Log updated!', {
              title: 'Call log management',
              variant: 'primary',
              solid: true
            })
          } else {
            this.$bvToast.toast('Failed to update log: ' + jwRes, {
              title: 'Call log management',
              variant: 'warning',
              solid: true
            })
          }
        })
      } else {
        var postData = Object.assign({}, this.callLogModal)
        delete postData.id
        this.$io.socket.post('/api/v1/calllog', postData, (resData, jwRes) => {
          if (jwRes.statusCode == 200) {
            this.$store.commit('addCallLog', resData)
            this.$bvToast.toast('Log created!', {
              title: 'Call log management',
              variant: 'primary',
              solid: true
            })
          } else {
            this.$bvToast.toast('Failed to create log: ' + jwRes, {
              title: 'Call log management',
              variant: 'warning',
              solid: true
            })
          }
        })
      }
    },
    hideCallLog (event) {
      if (event.trigger == "delete") {
        this.callLogModal.isHidden = true
        this.commitCallLog()
      }
    },
    sortingChanged(context) {
      this.$store.commit("setSortState", {tab: "miscellaneous", column: context.sortBy, descending: context.sortDesc})
    },
  }
}
</script>